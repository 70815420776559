<template>
  <div>
    <h1>Oeps! Pagina niet gevonden</h1>
    <p>De pagina die je zoekt bestaat niet.</p>
    <router-link class="button" to="/">Naar de startpagina</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
.button {
  padding: 8px 16px;
  background-color: var(--bg-color-1);
  color: var(--primary-color-dark);
  text-decoration: none;
}
</style>